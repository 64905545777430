<template>
  <div class="height-full flex-column">
    <div class="search">
      <DateSelect ref="dateSelect" @change="dateChange">订单日期</DateSelect>
      <div class="search_item m-r-10">
        <label class="label">商品名称</label>
        <el-input
          v-model.trim="searchObj.goodsName"
          class="search_item_cont"
          clearable
          placeholder="请输入商品名称"
          size="mini"
        >
        </el-input>
      </div>
      <div class="search_item m-r-10">
        <label class="label">买家名称</label>
        <el-input
          v-model.trim="searchObj.name"
          class="search_item_cont"
          clearable
          placeholder="请输入买家名称"
          size="mini"
        >
        </el-input>
      </div>
      <div class="search_item m-r-10">
        <label class="label">适用车型</label>
        <el-input
          v-model.trim="searchObj.vehModel"
          class="search_item_cont"
          clearable
          placeholder="请输入适用车型"
          size="mini"
        >
        </el-input>
      </div>
      <div class="search_item m-r-10">
        <label class="label">订单编号</label>
        <el-input
          v-model.trim="searchObj.orderCode"
          class="search_item_cont"
          clearable
          placeholder="请输入订单编号"
          size="mini"
        >
        </el-input>
      </div>
      <div class="search_item m-r-10">
        <label class="label">商品编码</label>
        <el-input
          v-model.trim="searchObj.goodsCode"
          class="search_item_cont"
          clearable
          placeholder="请输入商品编码"
          size="mini"
        >
        </el-input>
      </div>
      <div class="search_item m-r-10">
        <label class="label">生产批号</label>
        <el-input
          v-model.trim="searchObj.productBatch"
          class="search_item_cont"
          clearable
          placeholder="请输入生产批号"
          size="mini"
        >
        </el-input>
      </div>
      <!-- <div class="search_item m-r-10">
        <label class="label">开单类型</label>
        <ht-select
          v-model="searchObj.createTypes"
          :options="typeOptions"
          clearable
          filterable
          size="mini"
          placeholder="请选择"
          class="search_item_cont"
        />
      </div> -->
      <div class="search_item m-r-10">
        <label class="label">结算方式</label>
        <!-- <ht-select
          v-model="searchObj.paymentType"
          :options="paymentTypeOptions"
          clearable
          filterable
          
          size="mini"
          placeholder="请选择"
          class="search_item_cont"
        /> -->
        <el-select
          v-model="searchObj.paymentType"
          size="mini"
          clearable
          filterable
          remote
          collapse-tags
          placeholder="请选择"
          class="search_item_cont"
          :remote-method="getPaymentTypeList"
        >
          <el-option v-for="(item, index) in paymentTypeOptions" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div>
        <el-button type="primary" size="mini" @click="queryFn">查询(F5)</el-button>
        <el-button plain size="mini" @click="resetFn">重置(F6)</el-button>
      </div>
    </div>
    <div class="flex1 p-lr-10 flex-column overflow-hidden">
      <TableList
        class="table-list m-t-10"
        ref="orderListTable"
        :data="tableData"
        :columns="tableColumns"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <template #tableHeader="prop">
          <div class="m-l-10 flex flex-row-center flex-wrap">
            <span class="m-r-10">提交时间：{{ prop.item.submitAt }}</span>
            <span class="m-r-10">{{ prop.item.submitByName || "-" }}</span>
            <span class="m-r-10"
              >订单号：
              <span class="text-primary cursor-pointer" @click="toOrderDetail(prop.item)">
                {{ prop.item.orderCode }}
              </span>
            </span>
            <!-- <span v-if="prop.item.status == 7">（结算方式：{{ prop.item.paymentType }}）</span> -->
            <span>（{{ prop.item.paymentType }}）</span>
            <!-- <span :class="['m-lr-20', renderFlag(prop.item.orderType)]">{{ prop.item.orderType }}</span> -->
            <span class="m-r-10">
              <span>订单状态：</span>
              <span class="text-danger" v-if="[1, 2, 3, 9, 10, 11].includes(prop.item.status)">
                {{ prop.item.statusName }}
              </span>
              <span v-else>
                {{ prop.item.statusName }}
              </span>
              <span>（共</span>
              <span>{{ prop.item.details.length }}</span>
              <span>种，</span>
              <span>{{ prop.item.totalQty }}</span>
              <span>件商品 合计：￥</span>
              <span>{{ Number(prop.item.totalAmount).toFixed(2) }} </span>
              <span>）</span>
            </span>
            <span v-if="[11].includes(prop.item.status)">
              <span class="m-r-10" v-if="prop.item.buyerName">买家：{{ prop.item.buyerName }}</span>
              <span class="m-r-10" v-if="prop.item.createdAt">创建时间：{{ prop.item.createdAt }}</span>
              <span class="m-r-10" v-if="prop.item.createdName">{{ prop.item.createdName }}</span>
            </span>
          </div>
        </template>
        <template #cell="scope">
          <div v-if="scope.prop === 'code'" class="ellipsis">
            <el-tooltip :content="scope.row.code" placement="top">
              <span>{{ scope.row.code }}</span>
            </el-tooltip>
          </div>
          <div v-if="scope.prop === 'name'" class="text-primary cursor-pointer font-line-1">
            <el-tooltip :content="scope.row.name" placement="top">
              <div @click="toProductsDetails(scope.row)">{{ scope.row.name }}</div>
            </el-tooltip>
          </div>
          <div v-if="scope.prop === 'brand'">
            {{ scope.row.brand }}
          </div>
          <div v-if="scope.prop === 'productionPlace'">
            {{ scope.row.productionPlace }}
          </div>
          <div v-if="scope.prop === 'price'">
            <span> ￥{{ Number(scope.row.price).toFixed(2) }}/{{ scope.row.unitName }} </span>
          </div>
          <div v-if="scope.prop === 'buyParams'">
            {{ renderBuyParams(scope.row) }}
          </div>
          <div v-if="scope.prop === 'warehouse'">
            {{ scope.row.warehouse }}
          </div>
          <div v-if="scope.prop === 'productBatch'">
            {{ scope.row.productBatch }}
          </div>
          <div v-if="scope.prop === 'qty'">
            {{ scope.row.qty }}
          </div>
          <div v-if="scope.prop === 'outQty'">
            {{ scope.row.outQty }}
          </div>
          <div v-if="scope.prop === 'termQty'">
            {{ scope.row.termQty }}
          </div>
          <div v-if="scope.prop === 'receiveQty'">
            {{ scope.row.receiveQty }}
          </div>
        </template>
        <template #controls="scope">
          <div class="height-full flex flex-center">
            <div class="flex-column">
              <div
                class="text-danger font-size-12 text-center"
                v-if="[9].includes(scope.item.status) && scope.item.toCancelSecond"
              >
                <i class="el-icon-time"></i>
                <span>剩余<Countdown :timeValue="scope.item.toCancelSecond * 1000" @refresh="refreshFn"/></span>
              </div>
              <el-button
                type="text"
                v-if="[1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(scope.item.status)"
                @click="toOrderDetail(scope.item)"
              >
                订单详情
              </el-button>
              <!-- <el-button type="text" v-if="[9].includes(scope.item.status)" @click="payInfoFn(scope.item)">
                支付信息
              </el-button> -->
              <el-button
                type="text"
                v-if="
                  [9].includes(scope.item.status) &&
                    ['待付款-待卖家审核', '待付款-审核不通过'].includes(scope.item.statusName)
                "
                @click="payInfoFn(scope.item)"
              >
                支付信息
              </el-button>
              <el-button type="text" v-if="[1, 2, 11].includes(scope.item.status)" @click="editFn(scope.item)">
                修改订单
              </el-button>
              <el-button type="text" v-if="[11].includes(scope.item.status)" @click="submitFn(scope.item)">
                提交订单
              </el-button>
              <el-button type="text" v-if="[3].includes(scope.item.status)" @click="logisticInfoFn(scope.item)">
                物流信息
              </el-button>
              <el-button type="text" v-if="[3].includes(scope.item.status)" @click="confirmReceiptFn(scope.item)">
                确认收货
              </el-button>
              <!-- 一期不做 -->
              <!-- <el-button type="text" v-if="[10].includes(scope.item.status)" @click="toAppointFn(scope.item)">
                去预约
              </el-button>
              <el-button type="text" v-if="[4, 10].includes(scope.item.status)" @click="applyServiceFn(scope.item)">
                申请售后
              </el-button> -->
              <el-button
                type="text"
                class="text-danger-btn"
                v-if="[9].includes(scope.item.status)"
                @click="toPayFn(scope.item)"
              >
                去付款
              </el-button>
              <el-button type="text" v-if="[2, 4].includes(scope.item.status)" @click="buyAgainFn(scope.item)">
                再次购买
              </el-button>
              <el-button type="text" v-if="[1, 9].includes(scope.item.status)" @click="cancelFn(scope.item)">
                取消订单
              </el-button>
              <el-button type="text" v-if="[5, 6, 7].includes(scope.item.status)" @click="deleteFn(scope.item)">
                删除订单
              </el-button>
              <el-button
                type="text"
                v-if="[1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(scope.item.status)"
                @click="exportFn(scope.item)"
              >
                导出订单
              </el-button>
              <el-button
                type="text"
                v-if="[1, 2, 3, 4, 5, 6, 7, 9, 10, 11].includes(scope.item.status)"
                @click="orderLogFn(scope.item)"
              >
                订单日志
              </el-button>
            </div>
          </div>
        </template>
      </TableList>
    </div>

    <div class="page_footer m-b-10 p-l-10">
      <div>
        <el-checkbox v-model="checkedAll" @change="selectAllFn">全选当前查询结果</el-checkbox>
        <span class="m-lr-8">|</span>
        <span>已选中 {{ selectLen }} 条</span>
        <span class="m-lr-8">|</span>
        <span>批量操作</span>
        <el-button size="mini" type="primary" class="m-l-8" :disabled="!canPayBatch" @click="toPayBatchFn">
          去付款(F9)
        </el-button>
        <el-button
          size="mini"
          type="primary"
          class="m-l-8"
          :disabled="!canConfirmReceiptBatch"
          @click="confirmReceiptBatchFn"
        >
          确认收货(F10)
        </el-button>
        <el-button size="mini" type="primary" class="m-l-8" :disabled="!canDeleteBatch" @click="deleteBatchFn">
          删除(F3)
        </el-button>
        <el-button size="mini" type="primary" class="m-l-8" :disabled="!canCancelBatch" @click="cancelBatchFn">
          取消(ESC)
        </el-button>
        <el-button size="mini" type="primary" class="m-l-8" :disabled="!canExportBatch" @click="exportBatchFn">
          导出(O/Alt+O)
        </el-button>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next, jumper, sizes"
        :total="pageInfo.totalSize"
      >
      </el-pagination>
    </div>

    <!-- 订单日志弹框 -->
    <OrderLogBox :visible.sync="orderLogVisible" :curRow="curRow" />
    <!-- 支付信息弹框 -->
    <PayInfoBox :visible.sync="payInfoVisible" :curRow="curRow" />
    <!-- 物流信息弹框 -->
    <LogisticInfoBox :visible.sync="logisticInfoVisible" :curRow="curRow" />
    <!-- 确认收货弹框 -->
    <ConfirmReceiptBox :visible.sync="confirmReceiptVisible" :ids="ids" :query="searchObj" @refresh="refreshFn" />
    <!-- 在线预约/修改预约弹框 -->
    <AppointBox :visible.sync="appointVisible" :curRow="curRow" />

    <PlaceOrderType ref="placeOrderType" />
  </div>
</template>

<script>
import OrderLogBox from "../../components/OrderLogBox.vue";
import PayInfoBox from "../../components/PayInfoBox.vue";
import LogisticInfoBox from "../../components/LogisticInfoBox.vue";
import ConfirmReceiptBox from "../../components/ConfirmReceiptBox.vue";
import AppointBox from "../../components/AppointBox.vue";
import PlaceOrderType from "@/components/PlaceOrderType";
import DateSelect from "@/components/DateSelect";

import TableList from "./TableList.vue";
import Countdown from "../../components/Countdown.vue";

import { getOrderList, updateOrders, postOrdersDelete, postOrdersExport, ordersBatchCancel } from "@/api/order";
import { getDictsByType } from "@/api/base";

// import excel from "@/utils/excel";

const defaultFrom = {
  start: "", //开始日期
  end: "", //结束日期
  goodsName: "", //商品名称
  name: "", //买家名称
  vehModel: "", //适用车型
  orderCode: "", //订单编号
  goodsCode: "", //商品编码
  productBatch: "", //生产批号
  createTypes: "", //开单类型
  paymentType: "" //结算方式
};
export default {
  name: "OrderItem",
  components: {
    OrderLogBox,
    PayInfoBox,
    LogisticInfoBox,
    ConfirmReceiptBox,
    AppointBox,
    TableList,
    Countdown,
    PlaceOrderType,
    DateSelect
  },
  props: {
    tabItem: Object
  },
  data() {
    return {
      /**
       * 状态，多个用逗号分隔 1 : 待确认 2 : 待发货 3 : 待收货 4 : 已完成
       * 5 : 买家取消 6 : 卖家取消 7 : 系统取消
       * 9 : 待付款 10: 待服务 11: 待提交
       */
      range: [],
      searchObj: {
        ...defaultFrom
      },
      dateType: "本月", //订单日期
      dateOptions: [
        { id: 1, name: "本周" },
        { id: 2, name: "上周" },
        { id: 3, name: "本月" },
        { id: 4, name: "上月" },
        { id: 5, name: "今年" }
      ],
      typeOptions: [
        { id: null, name: "全部" },
        { id: 1, name: "线上订单" },
        { id: 2, name: "线下订单" },
        { id: 3, name: "代他人采购" }
      ],
      paymentTypeOptions: [],
      sort: "",
      tableData: [],
      pageInfo: {
        page: 1,
        totalSize: 0,
        pageSize: 20
      },
      selectionData: [], //已选中
      filterBatchData: [], //批量删除、取消、导出的数据
      selectLen: 0, //选中条数
      checkedAll: false,
      orderLogVisible: false,
      payInfoVisible: false, // 支付信息弹框
      // operateBatchVisible: false, //批量删除、取消、导出弹框
      // cancelVisible: false,
      logisticInfoVisible: false,
      confirmReceiptVisible: false,
      appointVisible: false,
      batchType: null,
      curRow: {},
      ids: []
    };
  },
  computed: {
    company() {
      return this.$store.state.companyInfo.company;
    },
    tableColumns() {
      const baseColumns1 = [
        {
          label: "商品编码",
          prop: "code",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "商品名称",
          prop: "name",
          width: "200px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "品牌",
          prop: "brand",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "产地",
          prop: "productionPlace",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "订货价",
          prop: "price",
          width: "100px",
          align: "center",
          slot: true
        },
        {
          label: "购买参数",
          prop: "buyParams",
          width: "120px",
          align: "center",
          slot: true
        }
      ];
      const warehouseObj = {
        label: "仓库",
        prop: "warehouse",
        align: "center",
        width: "100px",
        slot: true
      };
      const productBatchObj = {
        label: "生产批号",
        prop: "productBatch",
        align: "center",
        width: "100px",
        slot: true
      };
      const baseColumns2 = [
        {
          label: "订货数",
          prop: "qty",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "发货数量",
          prop: "outQty",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "终止数量",
          prop: "termQty",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        },
        {
          label: "收货数量",
          prop: "receiveQty",
          width: "100px",
          align: "center",
          sortable: "custom",
          slot: true
        }
      ];
      //库存展示维度是否勾选仓库
      if (this.company.setting.stockShowModeWarehouse) {
        baseColumns1.push(warehouseObj);
      }
      //库存展示维度是否勾选生产批号
      if (this.company.setting.stockShowModeProductBatch) {
        baseColumns1.push(productBatchObj);
      }
      return [...baseColumns1, ...baseColumns2];
    },
    canPayBatch() {
      return (
        ["0", "9"].includes(this.tabItem.id) &&
        ((this.selectionData.length && this.selectionData.some(item => [9].includes(item.status))) || this.checkedAll)
      );
    },
    canConfirmReceiptBatch() {
      return (
        ["0", "3"].includes(this.tabItem.id) &&
        ((this.selectionData.length && this.selectionData.some(item => [3].includes(item.status))) || this.checkedAll)
      );
    },
    canDeleteBatch() {
      return (
        ["0", "4,5,6,7"].includes(this.tabItem.id) &&
        ((this.selectionData.length && this.selectionData.some(item => [5, 6, 7, 11].includes(item.status))) ||
          this.checkedAll)
      );
    },
    canCancelBatch() {
      return (
        ["0", "1", "9"].includes(this.tabItem.id) &&
        ((this.selectionData.length && this.selectionData.some(item => [1, 9].includes(item.status))) ||
          this.checkedAll)
      );
    },
    canExportBatch() {
      return this.selectionData.length || this.checkedAll;
    }
  },
  mounted() {
    const arr = window.tool.getDateRangeByName("本月");
    this.searchObj = {
      ...defaultFrom,
      start: arr[0],
      end: arr[1],
      status: this.tabItem.id !== "0" ? this.tabItem.id : ""
    };
    this.getPaymentTypeList();
    this.initData();
  },
  activated() {
    if (this.$route.query.type === "refresh") {
      //刷新订单统计数量
      this.$bus.$emit("refreshOrdersCount");
      setTimeout(() => {
        this.initData();
      }, 200);
    }
  },
  methods: {
    renderBuyParams(row) {
      return row.goodsSpecification?.show;
    },
    getPaymentTypeList(query = "") {
      const params = {
        type: "paymentType",
        name: query
      };
      getDictsByType(params)
        .then(res => {
          this.paymentTypeOptions = res || [];
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    //刷新列表
    refreshFn() {
      //刷新订单统计数量
      this.$bus.$emit("refreshOrdersCount");
      this.pageInfo.page = 1;
      this.initData();
    },
    renderFlag(flag) {
      let str = "";
      switch (flag) {
        case "线上":
          str = "online";
          break;
        case "线下":
          str = "offline";
          break;
        case "代买":
          str = "buySthForSb";
          break;
      }
      return str;
    },
    dateChange(dateObj) {
      this.searchObj.start = dateObj.startDate;
      this.searchObj.end = dateObj.endDate;
    },
    //跳转到商品详情页
    toProductsDetails(item) {
      this.$router.push({
        path: "/products/details",
        query: {
          id: item.id,
          type: "order"
        }
      });
    },
    //查询
    queryFn() {
      this.pageInfo.page = 1;
      this.initData();
    },
    //重置
    resetFn() {
      this.searchObj = {
        ...defaultFrom,
        status: this.tabItem.id !== "0" ? this.tabItem.id : ""
      };
      this.$refs.dateSelect.reset();
      this.$refs.dateSelect.confirm();
    },
    sortChange(sort) {
      this.sort = sort;
      this.initData();
    },
    initData() {
      const params = {
        ...this.searchObj,
        page: this.pageInfo.page,
        pageSize: this.pageInfo.pageSize,
        orderBy: this.sort,
        status: this.tabItem.id !== "0" ? this.tabItem.id : ""
        //状态，多个用逗号分隔 1 : 待确认 2 : 待发货
        //3 : 待收货 4 : 已完成 5 : 买家取消 6 : 卖家取消
        // 7 : 系统取消 9 : 待付款 10: 待服务 11: 待提交
      };
      getOrderList(params)
        .then(res => {
          this.tableData = res.rows || [];
          this.pageInfo.totalSize = res.totalSize || 0;
          this.$refs.orderListTable.clearSelection(); //清空用户的选择
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    handleSelectionChange(val) {
      this.selectionData = val;
      this.selectLen = val.length;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    selectAllFn(foo) {
      if (foo) {
        this.selectLen = this.pageInfo.totalSize;
        // this.$refs.orderListTable.checkedAll(); //
      } else {
        this.selectLen = 0;
        this.$refs.orderListTable.clearSelection(); //清空用户的选择
      }
    },
    //去付款
    toPayBatchFn() {
      const obj = {
        ...this.searchObj,
        // status: this.tabItem.id !== "0" ? this.tabItem.id : "",
        ids: this.selectionData.map(item => item.id).join(",")
      };
      const param = {
        path: "/order/order-list/order-pay",
        query: {
          data: encodeURIComponent(JSON.stringify(obj))
        }
      };
      this.$router.push(param);
    },
    //确认收货
    confirmReceiptBatchFn() {
      if (!this.checkedAll) {
        this.ids = this.selectionData.map(item => item.id);
      }
      this.confirmReceiptVisible = true;
    },
    //批量删除
    deleteBatchFn() {
      const textTip = `是否确认删除所选订单？`;
      const filterBatchData = this.selectionData.filter(item => [5, 6, 7, 11].includes(item.status));
      this.$confirm(textTip, "提示", { type: "warning" })
        .then(() => {
          const params = {
            query: {
              ...this.searchObj,
              ids: filterBatchData.map(item => item.id).join(",")
            }
          };
          this.reqOrdersDelete(params, true);
        })
        .catch(() => {});
    },
    //取消
    cancelBatchFn() {
      const textTip = `是否确认取消所选订单？`;
      const filterBatchData = this.selectionData.filter(item => [9, 11].includes(item.status));
      this.$confirm(textTip, "提示", { type: "warning" })
        .then(() => {
          const params = {
            query: {
              ...this.searchObj,
              ids: filterBatchData.map(item => item.id).join(",")
            }
          };
          this.reqOrdersCancel(params);
        })
        .catch(() => {});
    },
    reqOrdersCancel(params) {
      ordersBatchCancel(params)
        .then(res => {
          this.$message.success(`成功取消${res}笔订单`);
          this.initData();
        })
        .catch(err => {
          this.$message.error(err?.message || "取消失败");
        });
    },
    //导出订单
    exportFn(row) {
      this.$confirm(`是否确认导出此订单？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            ids: row.id + "",
            exportType: "orderDetail" // 导出类型 order: 订单 orderDetail: 订单明细
          };
          this.reqOrdersExport(params);
        })
        .catch(() => {});
    },
    reqOrdersExport(params) {
      postOrdersExport(params)
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          const name = `订单列表_${this.$dayjs().format("YYYY-MM-DD")}.xls`;
          link.setAttribute("download", name);
          link.click();
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err?.message || "导出失败");
        });
    },
    //导出
    exportBatchFn() {
      const textTip = `是否确认导出所选订单？`;
      this.$confirm(textTip, "提示", { type: "warning" })
        .then(() => {
          const params = {
            ...this.searchObj,
            ids: this.selectionData.map(item => item.id).join(","),
            exportType: "orderDetail" // 导出类型 order: 订单 orderDetail: 订单明细
          };
          this.reqOrdersExport(params);
        })
        .catch(() => {});
    },
    //订单详情
    toOrderDetail(row) {
      const param = {
        path: "/order/order-list/order-detail",
        query: {
          id: row.id
        }
      };
      this.$router.push(param);
    },
    // 支付信息
    payInfoFn(row) {
      console.log("支付信息", row);
      this.curRow = { ...row };
      this.payInfoVisible = !this.payInfoVisible;
    },
    //修改订单
    editFn(row) {
      const { status } = row;
      let param = {};
      if ([11].includes(status)) {
        //待提交
        param = {
          path: "/order/order-list/modify-order-detail",
          query: {
            id: row.id
          }
        };
      } else if ([1, 2].includes(status)) {
        //待确认、待发货
        param = {
          path: "/order/order-list/modify-order-detail1",
          query: {
            id: row.id
          }
        };
      }

      this.$router.push(param);
    },
    //提交订单
    submitFn(row) {
      console.log("提交订单", row);
      //刷新订单统计数量
      // this.$bus.$emit("refreshOrdersCount");
    },
    //去付款
    toPayFn(row) {
      const obj = {
        ids: row.id + ""
      };
      const param = {
        path: "/order/order-list/order-pay",
        query: {
          data: encodeURIComponent(JSON.stringify(obj))
        }
      };
      this.$router.push(param);
    },
    //取消订单
    cancelFn(row) {
      this.$confirm(`是否确认取消此订单？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            id: row.id,
            status: 5 //5 : 买家取消
          };
          updateOrders(params)
            .then(() => {
              this.$message.success("操作成功");
              //刷新订单统计数量
              this.$bus.$emit("refreshOrdersCount");
              this.initData();
            })
            .catch(() => {
              this.$message.error("操作失败");
            });
        })
        .catch(() => {});
    },
    //删除订单
    deleteFn(row) {
      this.$confirm(`是否确认删除此订单？`, "提示", { type: "warning" })
        .then(() => {
          const params = {
            ids: [row.id]
          };
          this.reqOrdersDelete(params, false);
        })
        .catch(() => {});
    },
    reqOrdersDelete(params, isBatch) {
      postOrdersDelete(params)
        .then(res => {
          const text = isBatch ? `成功删除${res}笔订单` : "操作成功";
          this.$message.success(text);
          //刷新订单统计数量
          this.$bus.$emit("refreshOrdersCount");
          this.initData();
        })
        .catch(err => {
          this.$message.error(err?.message || "操作失败");
        });
    },
    //物流信息
    logisticInfoFn(row) {
      this.curRow = { ...row };
      this.logisticInfoVisible = true;
    },
    //确认收货
    confirmReceiptFn(row) {
      this.ids = [row.id];
      this.confirmReceiptVisible = true;
    },
    //去预约
    toAppointFn(row) {
      console.log("去预约", row);
      this.appointVisible = true;
    },
    //申请售后
    applyServiceFn(row) {
      console.log("申请售后", row);
      const param = {
        path: "/order/after-sale",
        query: {
          id: row.id
        }
      };
      this.$router.push(param);
    },
    //订单日志
    orderLogFn(row) {
      // console.log("订单日志", row);
      this.curRow = { ...row };
      this.orderLogVisible = true;
    },
    //再次购买
    buyAgainFn(row) {
      const data = window.tool.deepClone(row);
      this.$refs.placeOrderType.show({
        type: "orderList",
        data: data
      });
    },
    //导出订单
    exportFn1(row) {
      // const titleList = [
      //   "商品编码",
      //   "商品名称",
      //   "品牌",
      //   "产地",
      //   "单价",
      //   "订货数",
      //   "小计",
      //   "仓库",
      //   "生产批号",
      //   "商品分类",
      //   "车型",
      //   "商品性质",
      //   "规格",
      //   "生产码"
      // ];
      // const keyList = [
      //   "code",
      //   "name",
      //   "brand",
      //   "productionPlace",
      //   "price",
      //   "qty",
      //   "amount",
      //   "warehouse",
      //   "productBatch",
      //   "category",
      //   "vehModel",
      //   "property",
      //   "model",
      //   "productionCode"
      // ];
      // const dataList = row.details.map(item => ({
      //   code: item.code,
      //   name: item.name,
      //   brand: item.brand,
      //   productionPlace: item.productionPlace,
      //   price: item.price,
      //   qty: item.qty,
      //   amount: (Number(item.qty) * Number(item.price)).toFixed(2),
      //   warehouse: item.warehouse,
      //   productBatch: item.productBatch,
      //   category: item.category,
      //   vehModel: item.vehModel,
      //   property: item.property,
      //   model: item.model,
      //   productionCode: item.productionCode
      // }));
      // const params = {
      //   title: titleList,
      //   key: keyList,
      //   data: dataList, // 数据源
      //   autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
      //   filename: `${row.orderCode}_${this.$dayjs(new Date()).format("YYYY-MM-DD")}`
      // };
      // excel.exportArrayToExcel(params);
    }
  }
};
</script>

<style lang="less" scoped>
.search {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  background: #f5f5f5;
  /deep/.el-date-editor--daterange.el-input__inner {
    width: 220px;
  }
  .search_item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .label {
      width: 70px;
      margin-right: 4px;
      display: inline-block;
      text-align: right;
    }
    .search_item_cont {
      width: 180px;
    }
  }
}
.table-list {
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /deep/.el-button + .el-button {
    margin-left: 0;
  }
  /deep/.el-button--text {
    padding: 2px;
    margin: 2px;
  }
  .online {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 4px;
    background: #fe7448;
  }
  .offline {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 4px;
    background: #108ee9;
  }
  .buySthForSb {
    padding: 2px 8px;
    color: #ffffff;
    border-radius: 4px;
    background: #2eb026;
  }
}
</style>
